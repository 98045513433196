<!-- @format -->

<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="filterNoRead" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Message</h4>
        <b-badge pill variant="light-primary"> {{ filterNoRead }} NEWS </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar class="scrollable-container media-list scroll-area" tagname="li">
      <template v-if="systemNotifications.length > 0">
        <b-link v-for="notification in systemNotifications" :key="notification.id">
          <b-media @click="toRead(notification.id)">
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.fromUser.real_name }}
              </span>
              <span :class="['font-size-sm', notification.is_read == 0 ? '' : 'text-muted']">
                {{ notification.is_read == 0 ? 'Unread' : 'Read' }}
              </span>
            </p>
            <small class="notification-text">{{ notification.content }}</small>
          </b-media>
        </b-link>
      </template>
      <template v-else>
        <div class="media d-flex align-items-center">
          <h6 class="font-weight-bolder mr-auto mb-0">No message</h6>
        </div>
      </template>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <!-- 管理端 vip_message-list -->
      <!-- 会员端 mailbox_list -->
      <b-button v-if="isAdmin" :to="{ name: 'mailbox_list' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block>View all messages</b-button>
      <b-button v-else :to="{ name: 'vip_message-list' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block>View all messages</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'
import { nextTick, ref, onMounted, getCurrentInstance, reactive, computed } from '@vue/composition-api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const systemNotifications = reactive([])
    const filterNoRead = ref(0)
    const isAdmin = ref(false)
    const { proxy } = getCurrentInstance()
    const toRead = id => {
      axiosIns.get('/email/read', { params: { id } }).then(res => {
        console.log(res)
      })
    }
    onMounted(() => {
      nextTick(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData.role.indexOf('管理') > -1) {
          isAdmin.value = true
        }
        const resultId = ref([])
        const end = ref([])
        axiosIns.get('/email/no-read').then(res => {
          const { data } = res
          // 循环resultId，没有的id添加到resultId中
          data.forEach(item => {
            if (!resultId.value.includes(item.from_user_id)) {
              resultId.value.push(item.from_user_id)
              end.value.push(item)
            } else {
              const index = resultId.value.indexOf(item.from_user_id)
              if (new Date(end.value[index].created_at).getTime() < new Date(item.created_at).getTime()) {
                end.value[index] = item
              }
            }
          })
          systemNotifications.push(...end.value)
          filterNoRead.value = systemNotifications.filter(item => item.is_read == 0).length
        })
      })
    })
    return {
      toRead,
      filterNoRead,
      systemNotifications,
      isAdmin
    }
  },
}
</script>

<style></style>
