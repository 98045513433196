/**
 * /*
 *
 * Array of object
 *
 * Top level object can be:
 * 1. Header
 * 2. Group (Group can have navItems as children)
 * 3. navItem
 *
 * Supported Options
 *
 * /--- Header ---/
 *
 * header
 *
 * /--- nav Grp ---/
 *
 * title
 * icon (if it's on top level)
 * tag
 * tagVariant
 * children
 *
 * /--- nav Item ---/
 *
 * icon (if it's on top level)
 * title
 * route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
 * tag
 * tagVariant
 *
 * @format
 */

import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'

import vipForuntWeb from './vip-forunt-web'
// import InsurancePolicy from './Insurance-policy'
import GeneralManagement from './General-management'

const result = () => {
  const role = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).role : null
  if (role == '会员') {
    return [...vipForuntWeb]
  }
  if (role == '管理员') {
    return [...GeneralManagement]
  }
  return [...vipForuntWeb, ...GeneralManagement]
}

// Array of sections
export default result()
