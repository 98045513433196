/** @format */

// 总管理后台
export default [
  {
    header: 'Admin Management',
    role: ['admin'],
  },
  {
    route: 'general-dashboard',
    icon: 'TypeIcon',
    title: 'Admin',
  },

  {
    title: 'Products',
    icon: 'ShoppingBagIcon',
    role: ['admin'],
    children: [
      {
        title: 'Company',
        route: 'brand_management',
      },
      {
        title: 'Category',
        route: 'category_management',
      },
      {
        title: 'Product List',
        route: 'product_list',
      },
    ],
  },

  {
    title: 'Agent Member',
    role: ['admin'],
    icon: 'UsersIcon',
    children: [
      {
        title: 'level',
        route: 'level_management',
      },
      {
        title: 'Member List',
        route: 'member_management',
      },
      {
        title: 'Active Members',
        route: 'activeMember_management',
      },
      {
        title: 'Inactive members',
        route: 'notActiveMember_management',
      },
      {
        title: 'Downline Agent',
        route: 'downline_agent',
      },
    ],
  },

  {
    title: 'Policy Order',
    icon: 'CheckSquareIcon',
    route: 'policy_list',
    role: ['admin'],
    children: [
      {
        title: 'Policy List',
        route: 'policy_list',
      },
      {
        title: 'Submit a policy',
        route: 'policy_list_create',
      },
      {
        title: 'Team Commission',
        route: 'team_reward',
      },
      {
        title: 'Commission Report',
        route: 'Commission_record',
      },
      {
        title: 'Earnings Record',
        route: 'Earnings_record',
      },
      // {
      //   title: '支付记录',
      //   route: 'pay_record',
      // },
    ],
  },

  {
    title: 'Financial',
    icon: 'DollarSignIcon',
    role: ['admin'],
    children: [
      {
        title: 'TP Report',
        route: 'tp_data',
      },
      {
        title: 'commission Report ',
        route: 'commission_data',
      },
    ],
  },

  {
    title: 'Scoreboards',
    icon: 'BarChart2Icon',
    // route: 'vip_score-report',
    // children: [
    //   {
    //     title: 'Commission Report',
    //     route: 'vip_commission-report',
    //   },
    //   {
    //     title: 'Scoreboards',
    //     route: 'vip_score-report',
    //   },
    // ],
    children: [
      {
        title: 'Inforce TP',
        route: 'vip_inforce_TP',
        // href: 'https://api.myonewow.com/ziomyun/tprank.php',
      },
      {
        title: 'Pending TP',
        route: 'vip_pending_TP',
        // href: 'https://api.myonewow.com/ziomyun/ptprank.php',
      },
      {
        title: 'Recruiting',
        route: 'vip_recruiting',
        // href: 'https://api.myonewow.com/ziomyun/recruiting.php',
      },
    ],
  },
  {
    title: 'System',
    icon: 'SettingsIcon',
    role: ['admin'],
    children: [
      // {
      //   title: '菜单管理',
      //   route: 'menu_management',
      // },
      {
        title: 'article',
        route: 'article_management',
      },
      {
        title: 'category',
        route: 'article_category',
      },
      {
        title: 'Video', // 视频管理
        route: 'resource_management',
      },
      {
        title: 'Banner', // 轮播图
        route: 'banner_list',
      },
      {
        title: 'ATT agent', // 生日图
        route: 'birthday_list',
      },
    ],
  },

  {
    title: 'Chat',

    icon: 'MailIcon',
    route: 'mailbox_list',
  },
  {
    title: 'Calendar',
    role: ['admin'],

    icon: 'CalendarIcon',
    route: 'calendar_lit',
  },
]
